<template>
    <div class="zip-code-check">
        <h3 v-if="label">{{ label }}</h3>
        <form action="/" @submit.prevent.stop="handleSubmit">
            <div
                :class="[
                    'form-control relative w-full max-w-xs shadow-strong z-10',
                    {
                        'shake-horizontal': isLocationSupported === false,
                        'shake-vertical': isLocationSupported === true,
                    },
                ]"
            >
                <div class="input-group">
                    <v-select
                        ref="zipCity"
                        v-model="selectedZipCity"
                        input-id="zipCity"
                        :class="[
                            'zipCity',
                            'input',
                            'w-full',
                            'max-w-xs',
                            { error: wrongZipCode, focus: selectFocus },
                        ]"
                        placeholder="Postleitzahl"
                        :tabindex="0"
                        label="zipCode"
                        :filterable="false"
                        :clearable="false"
                        :options="zipCities"
                        :get-option-key="
                            (option) => option.zipCode + '-' + option.city
                        "
                        :loading="loading || loadingZipCities"
                        :clear-search-on-blur="() => false"
                        :dropdown-should-open="
                            ({ open, search }) => {
                                return (
                                    zipCities.length > 0 &&
                                    (search ? true : selectFocus)
                                )
                            }
                        "
                        @search:focus="selectFocus = true"
                        @search:blur="selectFocus = false"
                        @search="onSearch"
                    >
                        <template #search="{ attributes, events }">
                            <input
                                class="vs__search"
                                :required="!selectedZipCity"
                                maxlength="5"
                                v-bind="attributes"
                                title=""
                                @keydown="onKeydownFilter"
                                v-on="events"
                            />
                        </template>
                        <template #option="option">
                            <div v-if="option" class="d-center">
                                {{ option.zipCode + ' - ' + option.city }}
                            </div>
                        </template>
                        <template #selected-option="option">
                            <div v-if="option" class="selected d-center">
                                {{ option.zipCode + ' - ' + option.city }}
                            </div>
                        </template>
                    </v-select>

                    <butty class="accent small" type="submit">GO!</butty>
                </div>
            </div>

            <FormKit
                v-if="showCustomerGroups"
                v-model="selectedCustomerGroup"
                type="radio"
                class="bg-white"
                options-class="flex gap-4"
                wrapper-class="flex items-center"
                input-class="light"
                :options="customerGroups"
            />
        </form>
    </div>
</template>

<script>
import vSelect from 'vue-select'
import _each from 'lodash/each.js'
import useOrderStore from '~/stores/order.js'

export default {
    components: { vSelect },
    props: {
        showCustomerGroups: {
            type: Boolean,
            default: true,
        },

        zipCode: {
            type: String,
            default: '',
        },

        city: {
            type: String,
            default: '',
        },

        customerGroup: {
            type: String,
            default: 'private',
        },

        label: {
            type: String,
            default: 'Gib deine PLZ ein und buche unseren kostenlosen Service!',
        },

        tourType: {
            type: String,
            default: '',
        },

        b2bCustomerType: {
            type: String,
            default: '',
        },
    },

    emits: ['result'],
    async setup() {
        const [orderStore, log, i18n, scroll] = await Promise.all([
            useOrderStore(),
            useLog('zip-code-check'),
            useI18n(),
            useScroll(),
        ])

        return {
            orderStore,
            log,
            i18n,
            scroll,
        }
    },

    data() {
        let selectedZipCity = null
        const selectedCustomerGroup = this.customerGroup

        if (this.zipCode) {
            selectedZipCity = {
                id: this.zipCode + '-' + this.city,
                zipCode: this.zipCode,
                city: this.city,
            }
        }

        const customerGroups = {}
        customerGroups[Enums.customerGroups.private] =
            this.i18n.customerGroup.private
        customerGroups[Enums.customerGroups.corporate] =
            this.i18n.customerGroup.corporate

        return {
            selectedZipCity,
            zipCities: [],
            selectFocus: false,

            loading: false,
            loadingZipCities: false,
            wrongZipCode: false,

            selectedCustomerGroup,
            customerGroups,

            isLocationSupported: null,
        }
    },

    computed: {},

    watch: {
        zipCode(value) {
            if (value) {
                this.selectedZipCity = {
                    id: this.zipCode + '-' + this.city,
                    zipCode: this.zipCode,
                    city: this.city,
                }
            }
        },

        customerGroup(value) {
            this.selectedCustomerGroup = value
        },

        selectFocus(value) {
            if (value && this.$features.isTouchDevice) {
                setTimeout(() => {
                    this.scroll.toElement(this.$refs.zipCity.$el, {
                        align: 'center',
                    })
                }, 250)
            }
        },
    },

    methods: {
        onKeydownFilter(ev) {
            if (
                ![
                    '0',
                    '1',
                    '2',
                    '3',
                    '4',
                    '5',
                    '6',
                    '7',
                    '8',
                    '9',
                    'ArrowLeft',
                    'ArrowRight',
                    'Delete',
                    'Backspace',
                ].includes(ev.key) &&
                !(ev.key === 'a' && ev.ctrlKey)
            ) {
                ev.stopPropagation()
                ev.preventDefault()
            }
        },

        onSearch(query) {
            const vm = this

            if (query.length < 3) {
                vm.zipCities = []
                vm.wrongZipCode = false
                return false
            }

            vm.loadingZipCities = true

            $fetch
                .raw('/location', {
                    baseURL: vm.$nuxt.$config.public.sources.api.url,
                    method: 'POST',
                    body: {
                        zipCode: query,
                    },
                    credentials: 'include',
                })
                .then((response) => {
                    console.log(response)
                    if (response.status === 200) {
                        vm.zipCities = []
                        _each(response._data.data, (item) => {
                            vm.zipCities.push({
                                id: item.zipCode + '-' + item.city,
                                ...item,
                            })
                        })

                        vm.wrongZipCode = vm.zipCities.length === 0
                    } else {
                        vm.zipCities = []
                        vm.wrongZipCode = true
                    }

                    if (vm.wrongZipCode) {
                        vm.$refs.zipCity.clearSelection()
                    }

                    if (vm.zipCities.find((item) => item.zipCode === query)) {
                        const item = vm.zipCities.find(
                            (item) => item.zipCode === query,
                        )
                        vm.selectedZipCity = {
                            id: item.id,
                            zipCode: item.zipCode,
                            city: item.city,
                        }
                    }
                    vm.loadingZipCities = false
                })
        },

        handleSubmit() {
            const vm = this

            if (!vm.selectedZipCity || !vm.customerGroup) {
                return
            }
            vm.isLocationSupported = null

            let newB2bCustomerType = vm.b2bCustomerType
            if (
                !vm.$route.query.b2bCustomerType ||
                vm.selectedCustomerGroup === Enums.customerGroups.private
            ) {
                newB2bCustomerType = Enums.b2bCustomerType.general
            }

            vm.orderStore.$patch({
                settings: {
                    zipCode: vm.selectedZipCity.zipCode,
                    city: vm.selectedZipCity.city,
                    customerGroup: vm.selectedCustomerGroup,
                    tourType: vm.tourType,
                    b2bCustomerType: newB2bCustomerType,
                },
            })

            vm.$tracker.emit('zip-code.check', {
                zipCode: vm.selectedZipCity.zipCode,
                city: vm.selectedZipCity.city,
                customerGroup: vm.selectedCustomerGroup,
            })

            vm.loading = true
            vm.orderStore
                .fetchLocation()
                .then(async (data) => {
                    // Is location supported?
                    if (data.isLocationSupported) {
                        if (vm.orderStore.settings.tourType) {
                            // Is tour type supported?
                            if (
                                vm.orderStore.productTypes.includes(
                                    vm.orderStore.settings.tourType,
                                )
                            ) {
                                vm.isLocationSupported = true
                            } else {
                                vm.isLocationSupported = false
                            }
                        } else {
                            vm.isLocationSupported = true
                        }
                    } else {
                        vm.isLocationSupported = false
                    }
                    if (!vm.orderStore.isParcelShippingCarrier) {
                        vm.orderStore.$patch({
                            settings: {
                                b2bCustomerType: Enums.b2bCustomerType.general,
                            },
                        })
                    }

                    vm.$emit(
                        'result',
                        vm.isLocationSupported,
                        vm.orderStore.settings,
                    )

                    const state = vm.isLocationSupported ? 'success' : 'failed'
                    vm.$tracker.emit('zip-code.check.' + state, {
                        zipCode: vm.selectedZipCity.zipCode,
                        city: vm.selectedZipCity.city,
                        customerGroup: vm.selectedCustomerGroup,
                        b2bCustomerType: vm.b2bCustomerType,
                    })
                })
                .catch((_error) => {
                    vm.$emit('result', false, vm.orderStore.settings)
                })
                .finally(() => {
                    vm.loading = false
                })
        },
    },
}
</script>

<style src="vue-select/dist/vue-select.css"></style>
<style lang="scss" scoped>
form {
    min-height: 5.5rem;

    .form-control {
        &.shake-horizontal {
            animation: shake-horizontal 0.8s
                theme('transitionTimingFunction.easeInOutQuad') both;
        }
        &.shake-vertical {
            animation: shake-vertical 0.8s
                theme('transitionTimingFunction.easeInOutQuad') both;
        }
    }

    :deep(.butty) {
        margin: 0.25rem;
        height: calc(var(--rh-button-height) - 0.5rem);
        min-height: calc(var(--rh-button-height) - 0.5rem);
    }

    .input-group {
        background-color: #fff;
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
    }

    :deep(.formkit-fieldset) {
        padding: 1rem 0 0;
    }

    :deep(.formkit-outer) {
        margin: 0;
    }
}

:deep(.v-select) {
    --vs-search-input-placeholder-color: var(--rh-input-inactive-color);
    --vs-dropdown-option-padding: 0.375rem 1rem;
    --vs-line-height: 2rem;
    padding: 0;
    height: calc(var(--rh-button-height) - 0.5rem);
    border: none;
    background-color: #fff;

    &.error {
        [role='combobox'] {
            border-color: red;
        }
    }

    &.focus {
        .vs__selected {
            opacity: 0.4;
        }
    }

    &.vs--single.vs--searching:not(.vs--open):not(.vs--loading) {
        .vs__search {
            opacity: 1;
        }
    }

    [role='combobox'] {
        padding: 0;
        height: 100%;
        border-color: #fff;
    }

    .vs__selected {
        position: absolute;
        height: 100%;
        margin: 0;
        padding: 0 1rem;
        border: none;
        white-space: nowrap;
    }

    .vs__selected-options {
        overflow: hidden;
    }

    .vs__search {
        height: 100%;
        margin: 0;
        padding: 0 1rem;
        font-size: var(--rh-input-font-size);
        height: calc(var(--rh-input-height) + 2px);
        line-height: calc(var(--rh-input-height) + 2px);
        border: none;
    }

    .vs__spinner {
        border-radius: 50%;
        animation-delay: 0s;
        animation-duration: 0.6s;
    }

    .vs__open-indicator {
        display: none;
    }

    .vs__dropdown-menu {
        border: none;
    }

    .vs__dropdown-option {
        background-color: theme('colors.white');
        transition: background-color 0.25s;
        color: theme('colors.primary-dark');

        &.vs__dropdown-option--highlight {
            background-color: theme('colors.primary-light');
            color: theme('colors.primary-dark');
        }
    }
}

h3 {
    @apply text-lg;
    @apply font-semibold;
    margin-bottom: 0.75rem;
    max-width: 350px;
    width: 100%;
}

@screen sm {
    h3 {
        @apply text-xl;
        margin-bottom: 1rem;
    }
}
</style>
